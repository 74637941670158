.logo {
    display: flex;
    justify-content: center;
}

.logo-info {
    padding-top: 4rem;
    padding-bottom: 3rem;
    width: 10rem
}

#formlogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    padding: 0;
}

#formlogin .form {
    background-color: #f1f1f1;
    width: 100%;
    max-width: 480px;
    padding: 0.5rem;
}

#formlogin .field label {
    display: block;
}

#formlogin .field input {
    width: 100%;
}

#formlogin .actions {
    text-align: center;
    margin-top: 0.5rem;
}
