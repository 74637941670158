.input-date {
    width: 9rem;
}

@media screen and (min-width: 920px) {
    .input-date {
        width: 15rem;
    }
}

.tabela {
    position: relative !important;
}

thead  tr  th {
    position:sticky !important;
    top: 0 !important;
}

.col-style {
    border: 0.1rem solid rgb(0, 0, 0);
    border-radius: 0;
}

.col-bk-color {
    font-weight: bold;
    background-color: rgb(138, 138, 138);
}

